import hyperionEventSourcePerformanceSummarySlice from 'products/hyperion/operators/event-source-performance-summary/slices';
import hyperionPersonaSlice from 'products/hyperion/operators/persona/slices';
import hyperionPersonaConfigSlice from 'products/hyperion/operators/persona-config/slices';
import hyperionPersonaConfigVersionSlice from 'products/hyperion/operators/persona-config-version/slices';
import hyperionPersonaConfigVersionsSlice from 'products/hyperion/operators/persona-config-versions/slices';
import hyperionPersonaConfigsSlice from 'products/hyperion/operators/persona-configs/slices';
import hyperionPersonasSlice from 'products/hyperion/operators/personas/slices';

export const hyperion = {
  hyperion_persona_config: hyperionPersonaConfigSlice,
  hyperion_persona_configs: hyperionPersonaConfigsSlice,
  hyperion_personas: hyperionPersonasSlice,
  hyperion_persona: hyperionPersonaSlice,
  hyperion_persona_config_version: hyperionPersonaConfigVersionSlice,
  hyperion_persona_config_versions: hyperionPersonaConfigVersionsSlice,
  hyperion_event_source_performance_summary: hyperionEventSourcePerformanceSummarySlice,
};
