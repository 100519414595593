import { HyperionPersona } from '@cognitiv/galaxy-api';

export const hyperion_persona_default: HyperionPersona = {
  image_pre_signed_url: '',
  shopping_image_pre_signed_url: '',
  personality_image_pre_signed_url: '',
  interests_image_pre_signed_url: '',
  demographics_image_pre_signed_url: '',
  persona_id: 0,
  persona_config_version_id: 0,
  persona_config_id: 0,
  persona_config_name: '',
  cluster_id: 0,
  persona_state_id: 0,
  persona_state_name: '',
  state_name_formatted: '',
  positive_user_count: 0,
  positive_user_count_formatted: '',
  negative_user_count: 0,
  negative_user_count_formatted: '',
  user_count: 0,
  user_count_formatted: '',
  persona_config_version_total_user_count: 0,
  persona_config_version_total_user_count_formatted: '',
  user_count_percentage: null,
  user_count_percentage_abbr_formatted: '',
  positive_rate_sort: null,
  positive_rate: 0,
  positive_rate_formatted: '',
  persona_name: '',
  description: '',
  short_description: '',
  location_description: [],
  top_interests_description: [],
  occupation_description: [],
  financials_description: [],
  shopping_description: [],
  interests_description: [],
  personality_description: [],
  demographics_description: [],
  product_campaign_specific_description: [],
  image_prefix: '',
  shopping_image_prefix: '',
  personality_image_prefix: '',
  interests_image_prefix: '',
  demographics_image_prefix: '',
  created_date: '',
  uuid: '',
};
