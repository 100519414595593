export enum INSIGHT_PAGE_TYPE {
  EVENTS = 1,
  CAMPAIGNS = 2,
  MODELS = 3,
}

export enum SCORPIO_EVENT_STATE_ID {
  CONFIGURING = 1,
  TESTING = 2,
  ONLINE = 3,
  UNHEALTHY = 4,
}

export enum CAMPAIGN_DELIVERY_INSIGHT_TITLE {
  DEMOGRAPHICS = 'Demographics by Impressions',
  DOMAINS = 'Top Domains by Impressions{{ENTITY_NAME}}',
  GEOGRAPHICS = 'Regions by Impressions',
  TEMPORALS = 'Temporal Trends by Impressions',
  STATISTICS = 'Campaign Statistics by Delivery',
}

export enum CAMPAIGN_DELIVERY_INSIGHT_DESCRIPTION {
  DEMOGRAPHICS = 'Impression trends over {{WINDOW}}',
  DOMAINS = 'Impression trends over {{WINDOW}}',
  GEOGRAPHICS = 'Regions shaded by impressions over {{WINDOW}}',
  TEMPORALS = 'Impressions aggregated by {{TIME}} over {{WINDOW}}',
  STATISTICS = 'Delivery and performance stats for your campaign',
}

export enum CAMPAIGN_PERFORMANCE_INSIGHT_TITLE {
  DEMOGRAPHICS = 'Demographics by Conversions',
  DOMAINS = 'Top Domains by Conversions{{ENTITY_NAME}}',
  GEOGRAPHICS = 'Regions by Conversions',
  TEMPORALS = 'Temporal Trends by Conversions',
}

export enum CAMPAIGN_PERFORMANCE_INSIGHT_DESCRIPTION {
  DEMOGRAPHICS = 'Conversion trends over {{WINDOW}}',
  DOMAINS = 'Conversion trends over {{WINDOW}}',
  GEOGRAPHICS = 'Regions shaded by conversions  over {{WINDOW}}',
  TEMPORALS = 'Conversions aggregated by {{TIME}} over {{WINDOW}}',
  STATISTICS = 'Delivery and performance stats for your campaign',
}

export enum EVENT_INSIGHT_TITLE {
  KEYWORDS = 'Top Keywords',
  DEMOGRAPHICS = 'Demographics by CPI',
  DOMAINS = 'Top Domains{{ENTITY_NAME}}',
  GEOGRAPHICS = 'Regions by CPI',
  TEMPORALS = 'Temporal Trends by Users',
}

export enum EVENT_INSIGHT_DESCRIPTION {
  KEYWORDS = 'CPI & Impression trends over {{WINDOW}}',
  DEMOGRAPHICS = 'CPI trends over {{WINDOW}}',
  DOMAINS = 'CPI & Impression trends over {{WINDOW}}',
  GEOGRAPHICS = 'CPI & Impression trends over {{WINDOW}}',
  TEMPORALS = 'User Volume aggregated by {{TIME}} over {{WINDOW}}',
}

export enum MODEL_INSIGHT_TITLE {
  PREDICTIONS = 'Model Performance Trends',
  JOURNEYS = 'User Journeys Trends',
  FEATURES = 'Key Features',
  PLANNING = 'Campaign Planning',
}

export enum MODEL_INSIGHT_DESCRIPTION {
  PREDICTIONS = 'See how model prediction accuracy is driving campaign performance',
  JOURNEYS = 'Data aggregated by selected filters',
  FEATURES = 'Top features that are most relevant to your campaign performance',
  PLANNING = 'See how the increased budget can improve campaign performance',
}

export enum REPORT_TYPE {
  CSV = 'CSV',
  PDF = 'PDF',
  PPT = 'PPT',
}

export enum VISUALIZATION_COLOR {
  GRADIENT = 'Gradient',
  PURPLE = 'Purple',
  TEAL = 'Teal',
  BLUE = 'Blue',
}

export enum LEGEND_TYPE {
  CPI = 'CPI',
  DEMOGRAPHIC = 'Demographics',
  PERFORMANCE = 'Performance',
  MODEL = 'Model',
  FEATURE = 'Feature',
  NONE = '',
}

export enum INSIGHT_PATH {
  DEMOGRAPHICS = 'demographics',
  KEYWORDS = 'keywords',
  DOMAINS = 'domains',
  GEOGRAPHICS = 'geographics',
  TEMPORALS = 'temporals',
  PREDICTIONS = 'predictions',
  FEATURES = 'features',
  STATISTICS = 'statistics',
  USER_JOURNEYS = 'user-journeys',
  PLANNING = 'planning',
}

export enum DEMOGRAPHIC_TYPE {
  GENDER = 'Gender',
  AGE = 'Age',
  HOME_TYPE = 'Home Type',
  EDUCATION = 'Education',
  HOME_OWNERSHIP = 'Home Ownership',
  OCCUPATION = 'Occupation',
  INCOME = 'Annual Household Income',
  MARITIAL_STATUS = 'Maritial Status',
  CHILDREN = 'Children',
}

export enum INSIGHT_TYPE {
  DEMOGRAPHICS = 'Demographics',
  KEYWORDS = 'Keyword',
  DOMAINS = 'Domain',
  GEOGRAPHICS = 'Geographic',
  TEMPORALS = 'Temporal',
  STATISTICS = 'Statistics',
  PREDICTIONS = 'Predictions',
  PREDICTION_FEATURES = 'Prediction Features',
  USER_JOURNEY = 'User Journey',
}

export enum KPI_INSIGHT_TYPE {
  CPI = 'CPI',
  CPI_HOUR = 'CPI Hour',
  CPI_DAY = 'CPI Day',
  DELIVERY = 'Delivery',
  DELIVERY_HOUR = 'Delivery Hour',
  DELIVERY_DAY = 'Delivery Day',
  PERFORMANCE = 'Performance',
  PERFORMANCE_HOUR = 'Performance Hour',
  PERFORMANCE_DAY = 'Performance Day',
  DEFAULT = 'Default',
  AWARENESS = 'Awareness',
  CONSIDERATION = 'Consideration',
  PURCHASE_INTENT = 'Purchase Intent',
  CONVERSION = 'Conversion',
}

export enum TOOLTIP_TYPE {
  CPI = 'CPI',
}

export enum KPI_TYPE {
  CONVERSIONS = 'conversions',
  ROAS = 'roas',
  CPA = 'cpa',
  CVR = 'cvr',
  CTR = 'ctr',
}
