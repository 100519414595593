import React from 'react';
import { Outlet } from 'react-router-dom';
import useHyperionFetch from 'products/hyperion/template/hooks/useHyperionFetch';

import { Content } from 'components/layout/content/Content';
import { Menu } from 'components/menu/Menu';

import cn from 'products/hyperion/template/ApplicationTemplate.module.scss';

export default () => {
  useHyperionFetch();

  return (
    <div className={cn.template}>
      <Menu />
      <Content>
        <Outlet />
      </Content>
    </div>
  );
};
