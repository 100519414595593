import { TitanAdvertiserPerformance } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { titan_advertiser_performance_default } from 'products/titan/operators/advertiser-performance/defaults';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanAdvertiserPerformance = { ...titan_advertiser_performance_default };

export const titanAdvertiserPerformanceSlice = createSlice({
  name: SLICE.TITAN_ADVERTISER_PERFORMANCE,
  initialState,
  reducers: {
    clearTitanAdvertiserPerformance: () => initialState,
    setTitanAdvertiserPerformance: (_, action: PayloadAction<TitanAdvertiserPerformance>) => action.payload,
  },
});

export const { clearTitanAdvertiserPerformance, setTitanAdvertiserPerformance } = titanAdvertiserPerformanceSlice.actions;

export default titanAdvertiserPerformanceSlice.reducer;
