import React, { useCallback } from 'react';
import { Action, Button, Modal } from '@cognitiv/cassiopeia-ui';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Stack } from './Stack';

import cn from './Modal.module.scss';

export const Error = () => {
  const dispatch = useAppDispatch();
  const { name, message, stack, raw, is_open, error } = useAppSelector((state) => selectModal(state, 'error'));

  const onCloseError = useCallback(() => {
    dispatch(updateModal({ error: { is_open: false } }));
  }, [dispatch]);

  const onCloseStack = useCallback(() => {
    dispatch(updateModal({ stack: { is_open: false } }));
  }, [dispatch]);

  const onOpenStack = useCallback(() => {
    dispatch(updateModal({ stack: { is_open: true, stack: stack, raw: raw, onClose: onCloseStack } }));
  }, [dispatch, onCloseStack, raw, stack]);

  const uncaught = !error && !message;
  const more = !!stack || !!raw;
  const title = name || 'Something went wrong!';

  const formatted_message = message?.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <>
      <Modal identifier="error" is_open={is_open} width={440} onClose={onCloseError}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>
          {uncaught && (
            <p className={cn.message}>Please close this popup and try accessing the page again. If the error persists please contact Cognitiv.</p>
          )}
          {error && <p className={cn.message}>{error}</p>}
          {formatted_message && <p className={cn.message}>{formatted_message}</p>}
        </Modal.Body>
        <Modal.Footer>
          {more && (
            <Action variant="negative" onClick={onOpenStack}>
              Learn More
            </Action>
          )}
          <div className={cn.grow} />
          <Button onClick={onCloseError}>Ok</Button>
        </Modal.Footer>
      </Modal>
      <Stack />
    </>
  );
};
