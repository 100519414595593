import { EMPTY_ARRAY, EMPTY_FUNCTION } from '@cognitiv/cassiopeia-ui';
import { DemographicsContextProps, InsightPlanningContextProps, InsightReportContextProps } from 'products/scorpio/context/types';
import { DEMOGRAPHIC_TYPE, REPORT_TYPE } from 'products/scorpio/enums';
import { insight_report_default } from 'products/scorpio/overlays/manage-insight-comparison/constants';

export const demographics_initial_state: DemographicsContextProps = {
  demographic_type: DEMOGRAPHIC_TYPE.AGE,
  onPrevInsight: EMPTY_FUNCTION,
  onNextInsight: EMPTY_FUNCTION,
  setDemographic: EMPTY_FUNCTION,
  resetDemographic: EMPTY_FUNCTION,
};

export const report_initial_state: InsightReportContextProps = {
  filename: 'report',
  report_type: REPORT_TYPE.PDF,
  demographics: false,
  read_only: false,
  campaign_statistics: false,
  domains: false,
  keywords: false,
  temporals: false,
  geographics: false,
  user_journeys: false,
  predictions: false,
  features: false,
  setReportConfiguration: () => {},
};

export const default_insight_comparison_context = {
  primary_insight: { ...insight_report_default },
  comparison_insight: { ...insight_report_default },
  onSubmit: EMPTY_FUNCTION,
  resetInsightContext: EMPTY_FUNCTION,
  updateInsightLookback: EMPTY_FUNCTION,
};

export const prediction_default = {
  spend: 0,
  conversions: 0,
  impressions: 0,
  clicks: 0,
  is_predicted: false,
  is_trend_line: false,
  uuid: '',
  cpa: 0,
  cpm: 0,
  ctr: 0,
  conversion_rate: 0,
  spend_delta: 0,
  impressions_delta: 0,
  clicks_delta: 0,
  conversions_delta: 0,
  cpa_delta: 0,
  cpm_delta: 0,
  ctr_delta: 0,
  conversion_rate_delta: 0,
  spend_abbr_formatted: '',
  conversions_abbr_formatted: '',
  impressions_abbr_formatted: '',
  clicks_abbr_formatted: '',
  cpa_abbr_formatted: '',
  cpm_abbr_formatted: '',
  ctr_abbr_formatted: '',
  conversion_rate_formatted: '',
  spend_delta_formatted: '',
  impressions_delta_formatted: '',
  clicks_delta_formatted: '',
  conversions_delta_formatted: '',
  cpa_delta_formatted: '',
  cpm_delta_formatted: '',
  ctr_delta_formatted: '',
  conversion_rate_delta_formatted: '',
};

export const planning_data_default = { day: EMPTY_ARRAY, week: EMPTY_ARRAY, month: EMPTY_ARRAY };

export const planning_context_initial_state: InsightPlanningContextProps = {
  data: planning_data_default,
  period: [],
  predicted: [],
  historical: [],
  trend: [],
  prediction: { ...prediction_default },
  updatePlanningContext: EMPTY_FUNCTION,
};
