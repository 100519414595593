import { PERSONA_INDIVIDUAL_CATEGORY_PATH } from 'products/hyperion/enums';
import { INSIGHT_PATH } from 'products/scorpio/enums';

export const PUBLIC = {
  ROOT: {
    path: '/',
    element: null,
  },
  LOGIN: {
    path: '/login',
    element: null,
  },
  PASSWORD_REQUEST: {
    path: '/request-password',
    element: null,
  },
  PASSWORD_RESET: {
    path: '/reset-password/:auth_token',
    element: null,
  },
};

export const PRIVATE = {
  ROOT: {
    path: '/',
    element: null,
  },
};

export const FEATURES = {
  DEMOGRAPHICS: {
    path: INSIGHT_PATH.DEMOGRAPHICS,
    element: 'products/scorpio/folders/demographics/DemographicsPage',
  },
  KEYWORDS: {
    path: INSIGHT_PATH.KEYWORDS,
    element: 'products/scorpio/folders/keywords/KeywordsPage',
  },
  DOMAINS: {
    path: INSIGHT_PATH.DOMAINS,
    element: 'products/scorpio/folders/domains/DomainsPage',
  },
  GEOGRAPHICS: {
    path: INSIGHT_PATH.GEOGRAPHICS,
    element: 'products/scorpio/folders/geographics/GeographicsPage',
  },
  TEMPORALS: {
    path: INSIGHT_PATH.TEMPORALS,
    element: 'products/scorpio/folders/temporals/TemporalsPage',
  },
  PREDICTIONS: {
    path: INSIGHT_PATH.PREDICTIONS,
    element: 'products/scorpio/folders/predictions/PredictionsPage',
  },
  FEATURES: {
    path: INSIGHT_PATH.FEATURES,
    element: 'products/scorpio/folders/features/FeaturesPage',
  },
  USER_JOURNEYS: {
    path: INSIGHT_PATH.USER_JOURNEYS,
    element: 'products/scorpio/folders/journey/JourneyPage',
  },
  STATISTICS: {
    path: INSIGHT_PATH.STATISTICS,
    element: 'products/scorpio/folders/statistics/StatisticsPage',
  },
  PLANNING: {
    path: INSIGHT_PATH.PLANNING,
    element: 'products/scorpio/folders/planning/PlanningPage',
  },
};

export const CATEGORIES = {
  SHOPPING: {
    path: PERSONA_INDIVIDUAL_CATEGORY_PATH.SHOPPING,
    element: 'products/scorpio/pages/persona/folders/shopping/ShoppingPage',
  },
  INTERESTS: {
    path: PERSONA_INDIVIDUAL_CATEGORY_PATH.INTERESTS,
    element: 'products/scorpio/pages/persona/folders/interests/InterestsPage',
  },
  PERSONALITY: {
    path: PERSONA_INDIVIDUAL_CATEGORY_PATH.PERSONALITY,
    element: 'products/scorpio/pages/persona/folders/personality/PersonalityPage',
  },
  DEMOGRAPHICS: {
    path: PERSONA_INDIVIDUAL_CATEGORY_PATH.DEMOGRAPHICS,
    element: 'products/scorpio/pages/persona/folders/demographics/DemographicsPage',
  },
};

export const HYPERION = {
  APPLICATION: {
    path: '/applications',
    element: null,
  },
  INSIGHTS: {
    path: 'insights',
    element: null,
    PERSONAS: {
      path: 'personas',
      element: 'products/hyperion/pages/personas/PersonasPage',
      page_type_id: null,
      helmet: 'Persona Insights',
    },
    PERSONA_VERSIONS: {
      path: 'personas/:persona_config_id',
      element: 'products/hyperion/pages/persona-versions/PersonaVersionsPage',
      page_type_id: null,
      helmet: 'Persona Insights',
    },
    PERSONA: {
      path: 'personas/profiles/:persona_id',
      element: 'products/hyperion/pages/persona/PersonaPage',
      page_type_id: null,
      helmet: 'Persona Profile',
    },
  },
};

export const SCORPIO = {
  APPLICATION: {
    path: '/applications',
    element: null,
  },
  INSIGHTS: {
    path: 'insights',
    element: null,
    DEFAULT: {
      path: '',
      element: 'products/scorpio/pages/campaigns/CampaignsPage',
    },
    CAMPAIGNS: {
      path: 'campaigns',
      element: 'products/scorpio/pages/campaigns/CampaignsPage',
      helmet: 'Campaign Insights',
    },
    MODELS: {
      path: 'models',
      element: 'products/scorpio/pages/models/ModelsPage',
      helmet: 'Model Insights',
    },
    EVENTS: {
      path: 'events',
      element: 'products/scorpio/pages/events/EventsPage',
      helmet: 'Event Insights',
    },
    EVENT: {
      path: 'events/:event_source_id',
      element: 'products/scorpio/pages/event/EventPage',
      helmet: 'Event Insights',
      DEFAULT: {
        path: '',
        element: FEATURES.DEMOGRAPHICS.element,
      },
    },
    CAMPAIGN: {
      path: 'campaigns/:campaign_id',
      element: 'products/scorpio/pages/campaign/CampaignPage',
      helmet: 'Campaign Insights',
      DEFAULT: {
        path: '',
        element: FEATURES.STATISTICS.element,
      },
    },
    MODEL: {
      path: 'models/:model_id',
      element: 'products/scorpio/pages/model/ModelPage',
      helmet: 'Model Insights',
      DEFAULT: {
        path: '',
        element: FEATURES.PREDICTIONS.element,
      },
    },
  },
};

export const ORION = {
  APPLICATION: {
    path: '/applications/contextual',
    element: 'products/orion/pages/application/ApplicationPage',
    helmet: 'Contextual Application',
  },
  DEFAULT: {
    path: '',
    element: 'products/orion/pages/campaigns/CampaignsPage',
    helmet: 'Contextual Campaigns',
  },
  DOMAIN_LISTS: {
    path: 'domain-lists',
    element: 'products/orion/pages/domain-lists/DomainListsPage',
    helmet: 'Contextual Domain Lists',
  },
  DOMAIN_LIST: {
    path: 'domain-lists/:domain_list_id/domains',
    element: 'products/orion/pages/domain-list/DomainListPage',
    helmet: 'Contextual Domain List',
  },
  CAMPAIGNS: {
    path: 'campaigns',
    element: 'products/orion/pages/campaigns/CampaignsPage',
    helmet: 'Contextual Campaigns',
  },
  CAMPAIGN: {
    path: 'campaigns/:campaign_id',
    element: 'products/orion/pages/campaign/template/CampaignTemplate',
    helmet: 'Contextual Campaign',
    DEFAULT: {
      path: '',
      element: 'products/orion/pages/campaign/folders/segments/SegmentsPage',
    },
    SEGMENTS: {
      path: 'segments',
      element: 'products/orion/pages/campaign/folders/segments/SegmentsPage',
    },
    DEALS: {
      path: 'deals',
      element: 'products/orion/pages/campaign/folders/deals/DealsPage',
    },
  },
  SEGMENTS: {
    path: 'segments',
    element: 'products/orion/pages/segments/SegmentsPage',
    helmet: 'Contextual Segments',
  },
  SEGMENT: {
    path: 'segments/:segment_id',
    element: 'products/orion/pages/segment/template/SegmentTemplate',
    helmet: 'Contextual Segment',
    DEFAULT: {
      path: '',
      element: 'products/orion/pages/segment/SegmentPage',
      queries: { reference_text_type_id: 1, is_excluded: false },
    },
    FILTERS: {
      path: 'filters',
      element: 'products/orion/pages/segment/SegmentPage',
      queries: { reference_text_type_id: 1, is_excluded: false },
    },
  },
  DEALS: {
    path: 'deals',
    element: 'products/orion/pages/deals/DealsPage',
    helmet: 'Contextual Deals',
  },
  DEAL: {
    path: 'deals/:deal_id',
    element: 'products/orion/pages/deal/template/DealTemplate',
    helmet: 'Contextual Deal',
    DEFAULT: {
      path: '',
      element: 'products/orion/pages/deal/folders/filter/FilterPage',
    },
    FILTER: {
      path: 'filter',
      element: 'products/orion/pages/deal/folders/filter/FilterPage',
    },
  },
};

export const LIBRA = {
  APPLICATION: {
    path: '/applications',
    element: 'products/libra/template/ApplicationTemplate',
  },
  DSP_SEAT_IDENTIFIERS: {
    path: 'dsp-identifiers',
    element: 'products/libra/pages/dsp-seat-identifiers/DspSeatIdentifiersPage',
    helmet: 'DSP Seat Identifiers',
  },
};

export const TITAN = {
  APPLICATION: {
    path: '/applications',
    element: 'products/titan/template/ApplicationTemplate',
  },
  ADVERTISERS: {
    path: 'advertisers',
    element: 'products/titan/pages/advertisers/AdvertisersPage',
    helmet: 'Advertisers',
  },
  ADVERTISER: {
    path: 'advertisers/:advertiser_id',
    element: 'products/titan/pages/advertiser/template/AdvertiserTemplate',
    helmet: 'Advertiser',
    INSIGHTS: {
      path: 'insights',
      element: 'products/titan/pages/advertiser/template/InsightsTemplate',
      EVENTS: {
        path: 'events',
        element: 'products/titan/pages/advertiser/folders/insights/InsightEventsPage',
      },
      CAMPAIGNS: {
        path: 'campaigns',
        element: 'products/titan/pages/advertiser/folders/insights/InsightCampaignsPage',
      },
      MODELS: {
        path: 'models',
        element: 'products/titan/pages/advertiser/folders/insights/InsightModelsPage',
      },
      PERSONAS: {
        path: 'personas',
        element: 'products/titan/pages/advertiser/folders/insights/InsightPersonasPage',
      },
    },
    CONTEXTUAL: {
      path: 'contextual',
      element: 'products/titan/pages/advertiser/template/ContextualTemplate',
      CAMPAIGNS: {
        path: 'campaigns',
        element: 'products/titan/pages/advertiser/folders/contextual/ContextualCampaignsPage',
      },
      SEGMENTS: {
        path: 'segments',
        element: 'products/titan/pages/advertiser/folders/contextual/ContextualSegmentsPage',
      },
      DEALS: {
        path: 'deals',
        element: 'products/titan/pages/advertiser/folders/contextual/ContextualDealsPage',
      },
    },
  },
};
