import React from 'react';
import { Outlet } from 'react-router';
import { useScorpioFetch } from 'products/scorpio/templates/hooks/useScorpioFetch';

import { Content } from 'components/layout/content/Content';
import { Menu } from 'components/menu/Menu';

import cn from 'products/scorpio/templates/Template.module.scss';

export default () => {
  useScorpioFetch();

  return (
    <div className={cn.template}>
      <Menu />
      <Content>
        <Outlet />
      </Content>
    </div>
  );
};
