import { ScorpioModel } from '@cognitiv/galaxy-api';

export const scorpio_model_default: ScorpioModel = {
  advertiser_id: 0,
  advertiser_name: '',
  partner_id: 0,
  partner_name: '',
  state_id: 0,
  state_name: '',
  product_type_id: 0,
  product_type_name: '',
  model_name: '',
  model_id: 0,
  expose_user_journey: false,
  expose_campaign_planing: false,
  uuid: '',
};
