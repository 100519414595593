import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@cognitiv/cassiopeia-ui';
import { OrionDomainList, useClientContext } from '@cognitiv/galaxy-api';
import axios, { AxiosResponse } from 'axios';
import { selectModal } from 'ducks/modals/selectors';
import { updateOrionAdvertiserDomainListModal } from 'ducks/modals/slices';
import { ORION_DOMAIN_LIST_UPLOAD_STATE } from 'products/orion/enums';
import { DomainListForm } from 'products/orion/modals/manage-orion-advertiser-domain-list/components/DomainListForm';
import { DomainListProcessing } from 'products/orion/modals/manage-orion-advertiser-domain-list/components/DomainListProcessing';
import { configuration_default } from 'products/orion/modals/manage-orion-advertiser-domain-list/defaults';
import { OrionDomainListConfiguration } from 'products/orion/modals/manage-orion-advertiser-domain-list/types';
import { orion_domain_list_default } from 'products/orion/operators/domain-list/defaults';
import { selectOrionDomainList } from 'products/orion/operators/domain-list/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const ManageOrionAdvertiserDomainList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const orion_domain_list = useAppSelector(selectOrionDomainList);
  const { Orion, handleError } = useClientContext();
  const [form, setForm] = useState<OrionDomainList>({ ...orion_domain_list_default });
  const [configuration, setConfiguration] = useState<OrionDomainListConfiguration>({ ...configuration_default });

  const { is_open, advertiser_id, advertiser_name, segment_id, segment_name, error_instructions, onFailure, onSuccess } = useAppSelector((state) =>
    selectModal(state, 'manage_orion_advertiser_domain_list'),
  );

  useEffect(() => {
    setForm({ ...orion_domain_list, advertiser_id, advertiser_name, segment_id, segment_name });
  }, [advertiser_id, advertiser_name, segment_id, segment_name, orion_domain_list]);

  const onChangeDomainList = useCallback((item: Partial<OrionDomainList>) => {
    setForm((prev) => ({ ...prev, ...item }));
  }, []);

  const onChangeConfiguration = useCallback((item: Partial<OrionDomainListConfiguration>) => {
    setConfiguration((prev) => ({ ...prev, ...item }));
  }, []);

  const onClose = useCallback(() => {
    setConfiguration({ ...configuration_default });
    dispatch(updateOrionAdvertiserDomainListModal({ is_open: false }));
  }, [dispatch]);

  const readDomainListFile = (file: File): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        if (!e.target) {
          reject(new Error('File target is null'));
          return;
        }
        resolve(e.target.result as ArrayBuffer);
      };

      reader.onerror = function (err) {
        reject(err);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const uploadDomainList = async (host: string, payload: ArrayBuffer) => {
    // DEV NOTE: axios set default headers on all requests
    axios.defaults.headers = {};
    return new Promise((resolve, reject) => {
      axios({
        method: 'put',
        url: host,
        data: payload,
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  const processDomainList = useCallback(
    async (domain_list_id: number, presigned_url: string) => {
      if (configuration.files.length === 0) throw new Error('File is missing from dropzone');
      setForm((prev) => ({ ...prev, domain_list_id }));
      setConfiguration((prev) => ({
        ...prev,
        message: 'Domain List is being processed by AWS',
        polling: true,
        count: 0,
      }));

      const payload = await readDomainListFile(configuration.files[0]);
      await uploadDomainList(presigned_url, payload);
      await Orion.finishOrionDomainListUpload(domain_list_id);
    },
    [Orion, configuration.files],
  );

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!configuration.files.length) return;

      try {
        const { advertiser_id, domain_list_name } = form;

        if (orion_domain_list.domain_list_id) {
          await Orion.updateOrionDomainList(orion_domain_list.domain_list_id, { domain_list_name });
          const presigned_url: AxiosResponse['data'] = await Orion.startOrionDomainListUpload(orion_domain_list.domain_list_id);
          await processDomainList(orion_domain_list.domain_list_id, presigned_url);
        } else {
          const domain_list_id = await Orion.createOrionAdvertiserDomainList(Number(advertiser_id), { domain_list_name });
          setForm((prev) => ({ ...prev, domain_list_id }));
          const presigned_url: AxiosResponse['data'] = await Orion.startOrionDomainListUpload(domain_list_id);
          await processDomainList(domain_list_id, presigned_url);
        }
      } catch (err) {
        setConfiguration((prev) => ({ ...prev, polling: false, count: 0 }));
        handleError(err);
      }
    },
    [Orion, processDomainList, form, handleError, orion_domain_list.domain_list_id, configuration.files.length],
  );

  useEffect(() => {
    let interval: NodeJS.Timeout | number = 0;
    if (configuration.polling) {
      interval = setInterval(() => setConfiguration((prev) => ({ ...prev, count: prev.count + 1 })), 1000);
    }
    return () => clearInterval(interval);
  }, [configuration.polling]);

  useEffect(() => {
    if (!configuration.polling) return;
    if (configuration.count % 5 === 0) {
      const validateFileUpload = async () => {
        try {
          const res = await Orion.getOrionDomainListUploadState(form.domain_list_id);

          if (res === ORION_DOMAIN_LIST_UPLOAD_STATE.FAILED) {
            const message: AxiosResponse['data'] = await Orion.getOrionDomainListUploadFailureReason(form.domain_list_id);
            await onFailure(form.domain_list_id);
            dispatch(updateOrionAdvertiserDomainListModal({ is_open: false }));
            throw new Error(`${error_instructions} Reason: ${message}`);
          }

          if (res === ORION_DOMAIN_LIST_UPLOAD_STATE.SUCCEEDED) {
            setConfiguration((prev) => ({ ...prev, files: [], polling: false, count: 0 }));
            await onSuccess(form.domain_list_id);
            dispatch(updateOrionAdvertiserDomainListModal({ is_open: false }));
          }
        } catch (err) {
          handleError(err);
          setConfiguration((prev) => ({ ...prev, files: [], polling: false, count: 0 }));
        }
      };
      validateFileUpload();
    }
  }, [
    dispatch,
    handleError,
    navigate,
    configuration.polling,
    configuration.count,
    orion_domain_list.domain_list_id,
    segment_id,
    form,
    Orion,
    onFailure,
    onSuccess,
    error_instructions,
  ]);

  const entity_exists = !!orion_domain_list.domain_list_id;

  return (
    <Modal is_open={is_open} onClose={onClose} identifier="manage_orion_advertiser_domain_list" onSubmit={onSubmit}>
      <Modal.Header>{entity_exists ? 'Edit Domain List' : 'Upload Domain List'}</Modal.Header>
      {configuration.polling && <DomainListProcessing message={configuration.message} />}
      {!configuration.polling && (
        <DomainListForm
          form={form}
          files={configuration.files}
          onChangeDomainList={onChangeDomainList}
          onChangeConfiguration={onChangeConfiguration}
          entity_exists={entity_exists}
          segment_page={!!segment_id}
        />
      )}
    </Modal>
  );
};
