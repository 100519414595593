import React, { useCallback } from 'react';
import { Button, EMPTY_FUNCTION, Modal } from '@cognitiv/cassiopeia-ui';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import cn from './Modal.module.scss';

export const Confirm = () => {
  const dispatch = useAppDispatch();

  const { is_open, title, message, label, onConfirm = EMPTY_FUNCTION } = useAppSelector((state) => selectModal(state, 'confirm'));

  const onConfirmClick = useCallback(() => {
    onConfirm();
    dispatch(updateModal({ confirm: { is_open: false } }));
  }, [dispatch, onConfirm]);

  const onClose = useCallback(() => {
    dispatch(updateModal({ confirm: { is_open: false } }));
  }, [dispatch]);

  return (
    <Modal identifier="confirm" is_open={is_open} width={440} onClose={onClose}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        <p className={cn.message}>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="subtle" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onConfirmClick}>{label || 'Confirm'}</Button>
      </Modal.Footer>
    </Modal>
  );
};
