import React, { useCallback } from 'react';
import { Button, EditorShell, Modal } from '@cognitiv/cassiopeia-ui';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const Stack = () => {
  const dispatch = useAppDispatch();
  const { stack, raw } = useAppSelector((state) => selectModal(state, 'error'));
  const { is_open } = useAppSelector((state) => selectModal(state, 'stack'));

  const onClose = useCallback(() => {
    dispatch(updateModal({ stack: { is_open: false } }));
  }, [dispatch]);

  return (
    <Modal identifier="stack" is_open={is_open} width={800} onClose={onClose}>
      <Modal.Header>Stack</Modal.Header>
      <Modal.Body>
        <EditorShell theme_identifier="html" min_height={400} min_width={760} value={raw || stack || ''} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Ok</Button>
      </Modal.Footer>
    </Modal>
  );
};
