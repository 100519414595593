export enum PERSONA_INDIVIDUAL_CATEGORY_ID {
  LOCATION = 1,
  OCCUPATION = 2,
  TOP_INTERESTS = 3,
  FINANCIALS = 4,
}
export enum PERSONA_INDIVIDUAL_CATEGORY_PATH {
  SHOPPING = 'shopping',
  INTERESTS = 'interests',
  PERSONALITY = 'personality',
  DEMOGRAPHICS = 'demographics',
}
