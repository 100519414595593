import React from 'react';
import { Icon, Tooltip } from '@cognitiv/cassiopeia-ui';
import classNames from 'classnames';

import cn from 'components/menu/components/Components.module.scss';
import { useMenuGroupContext } from 'components/menu/components/MenuGroup';
import { menu_theme } from 'components/menu/components/theme';
import { useMenuContext } from 'components/menu/context/MenuContext';
import { MenuActionProps } from 'components/menu/types';

export const MenuAction = ({ onClick, icon, label, children, className, override = false, message = '' }: MenuActionProps) => {
  const { is_menu_open } = useMenuContext();
  const { is_expanded } = useMenuGroupContext();

  const content = override || (!is_menu_open && !is_expanded) ? (!override ? label : message) : null;

  return (
    <Tooltip content={content}>
      <div className={classNames(cn.action, className, { [cn.open]: is_menu_open })} onClick={onClick}>
        {icon && <Icon icon={icon} theme={menu_theme.large} />}
        {children}
        <p className={cn.label}>{label}</p>
      </div>
    </Tooltip>
  );
};
