import { useEffect } from 'react';
import { batch } from 'react-redux';
import { useClientContext } from '@cognitiv/galaxy-api';
import { selectSettings } from 'ducks/settings/selectors';
import { setScorpioAdvertisers } from 'products/scorpio/operators/advertisers/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const useHyperionFetch = () => {
  const dispatch = useAppDispatch();
  const { hyperion } = useAppSelector(selectSettings);
  const { Scorpio, handleError } = useClientContext();

  useEffect(() => {
    const getData = async () => {
      try {
        const [scorpio_advertisers] = await Promise.all([Scorpio.getScorpioAdvertisers()]);
        batch(() => {
          dispatch(setScorpioAdvertisers(scorpio_advertisers));
        });
      } catch (err) {
        handleError(err);
      }
    };
    if (hyperion) {
      getData();
    }
  }, [dispatch, handleError, Scorpio, hyperion]);
};

export default useHyperionFetch;
