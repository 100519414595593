import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import cn from 'components/layout/navigation/Navigation.module.scss';

export const Breadcrumb: FC<{ path?: string; label: string; prefix?: string }> = ({ path, label, prefix }) => {
  const title = prefix ? prefix + label : label;

  return (
    <>
      <div className={classNames(cn.breadcrumb, { [cn.static]: !path })}>{path ? <Link to={path}>{title}</Link> : <p>{title}</p>}</div>
      <FontAwesomeIcon icon={faCaretRight} className={cn.icon} />
    </>
  );
};
