import React from 'react';
import { Modal } from '@cognitiv/cassiopeia-ui';

import { SmallSpinner } from 'components/layout/loading/Loading';

export const DomainListProcessing = ({ message }: { message: string }) => {
  return (
    <Modal.Body>
      <SmallSpinner />
      <div>
        <p>{message}</p>
      </div>
    </Modal.Body>
  );
};
