import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Modals } from 'ducks/modals/types';
import { SLICE } from 'ducks/slice';
import { libra_modals } from 'products/libra/modals/constant';
import { manage_orion_advertiser_domain_list_default, orion_modals } from 'products/orion/modals/constants';
import { AdvertiserDomainListModal } from 'products/orion/modals/types';
import { scorpio_modals } from 'products/scorpio/modals/constants';
import { titan } from 'products/titan/modals/constants';

const initialState: Modals = {
  error: {
    is_open: false,
    error: null,
    name: null,
    message: null,
    stack: null,
    status: null,
    raw: null,
  },
  confirm: {
    is_open: false,
    title: 'Confirm Action',
    message: 'Please confirm action before proceeding.',
    label: 'Confirm',
    onConfirm: () => {},
  },
  stack: {
    is_open: false,
  },
  ...scorpio_modals,
  ...libra_modals,
  ...orion_modals,
  ...titan,
};

export const modalsSlice = createSlice({
  name: SLICE.MODALS,
  initialState,
  reducers: {
    updateModal: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateOrionAdvertiserDomainListModal: (state, action: PayloadAction<Partial<AdvertiserDomainListModal>>) => {
      return { ...state, manage_orion_advertiser_domain_list: { ...manage_orion_advertiser_domain_list_default, ...action.payload } };
    },
  },
});

export const { updateModal, updateOrionAdvertiserDomainListModal } = modalsSlice.actions;

export default modalsSlice.reducer;
