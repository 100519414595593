import React from 'react';
import { Outlet } from 'react-router';
import { Navigation } from 'components/layout/navigation/Navigation';
import { Breadcrumb } from 'components/layout/navigation/Breadcrumb';
import { Content } from 'components/layout/content/Content';
import { Menu } from 'components/menu/Menu';

import cn from 'products/public/pages/error/templates/ErrorTemplate.module.scss';

export default () => {
  return (
    <div className={cn.template}>
      <Menu />
      <Content>
        <Navigation>
          <Breadcrumb label="404" />
        </Navigation>
        <Outlet />
      </Content>
    </div>
  );
};
