import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CAMPAIGN_DELIVERY_INSIGHT_DESCRIPTION, CAMPAIGN_DELIVERY_INSIGHT_TITLE } from 'products/scorpio/enums';
import { ScorpioCampaignStatisticInsight } from 'products/scorpio/operators/campaign-statistics/types';
import { SLICE } from 'products/scorpio/operators/slice';

export const initialState: ScorpioCampaignStatisticInsight = {
  title: CAMPAIGN_DELIVERY_INSIGHT_TITLE.STATISTICS,
  description: CAMPAIGN_DELIVERY_INSIGHT_DESCRIPTION.STATISTICS,
  data: [],
  data_unavailable: true,
  csv: [],
};

export const ScorpioCampaignStatisticsSlice = createSlice({
  name: SLICE.SCORPIO_CAMPAIGN_STATISTICS,
  initialState,
  reducers: {
    clearScorpioCampaignStatistics: () => {
      return initialState;
    },

    setScorpioCampaignStatistics(_, action: PayloadAction<ScorpioCampaignStatisticInsight>) {
      return action.payload;
    },
  },
});

export const { clearScorpioCampaignStatistics, setScorpioCampaignStatistics } = ScorpioCampaignStatisticsSlice.actions;

export default ScorpioCampaignStatisticsSlice.reducer;
