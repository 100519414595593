import React, { useEffect } from 'react';
import { batch } from 'react-redux';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
import { parseQueries } from '@cognitiv/cicada-ui';
import { useClientContext } from '@cognitiv/galaxy-api';
import { selectSettings } from 'ducks/settings/selectors';
import { ManageTitanAdvertiser } from 'products/titan/modals/manage-titan-advertiser/ManageTitanAdvertiser';
import { setTitanPartners } from 'products/titan/operators/partners/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Content } from 'components/layout/content/Content';
import { Menu } from 'components/menu/Menu';

import cn from 'products/titan/template/ApplicationTemplate.module.scss';

export default () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { update } = parseQueries(search);
  const { titan } = useAppSelector(selectSettings);
  const { Titan, handleError } = useClientContext();

  useEffect(() => {
    const getData = async () => {
      try {
        const [titan_partners] = await Promise.all([Titan.getTitanPartners()]);

        batch(() => {
          dispatch(setTitanPartners(titan_partners));
        });
      } catch (err) {
        handleError(err);
      }
    };
    if (titan) {
      getData();
    }
  }, [dispatch, update, Titan, handleError, titan]);

  return (
    <div className={cn.template}>
      <Menu />
      <Content>
        <Outlet />
      </Content>
      <ManageTitanAdvertiser />
    </div>
  );
};
