import { ScorpioInsightReport } from '@cognitiv/galaxy-api';
import { AggregationTypeOption, InsightReport } from 'products/scorpio/overlays/manage-insight-comparison/types';
import { uuidv4 } from 'utils/uuid';

export const insight_report_default: InsightReport = {
  entity_id: undefined,
  entity_name: undefined,
  start_date: undefined,
  finish_date: undefined,
  aggregation_type_id: undefined,
};

export enum SCORPIO_AGGREGATION {
  DAY = 1,
  WEEK = 2,
  MONTH = 3,
}

export const SCORPIO_STOCK_REPORT_LABELS = {
  [SCORPIO_AGGREGATION.DAY]: 'Yesterday',
  [SCORPIO_AGGREGATION.WEEK]: 'Last Week',
  [SCORPIO_AGGREGATION.MONTH]: 'Last Month',
};

export const SCORPIO_AGGREGATION_TYPE_LABELS = {
  [SCORPIO_AGGREGATION.DAY]: 'Day',
  [SCORPIO_AGGREGATION.WEEK]: 'Week',
  [SCORPIO_AGGREGATION.MONTH]: 'Month',
};

export const SCORPIO_AGGREGATION_REPORTS_COUNT = {
  [SCORPIO_AGGREGATION.DAY]: 15,
  [SCORPIO_AGGREGATION.WEEK]: 5,
  [SCORPIO_AGGREGATION.MONTH]: 4,
};

export const scorpio_stock_report_options: ScorpioInsightReport[] = [
  {
    report_name: SCORPIO_STOCK_REPORT_LABELS[SCORPIO_AGGREGATION.DAY],
    aggregation_type_id: SCORPIO_AGGREGATION.DAY,
    start_date: undefined,
    finish_date: undefined,
    is_current: false,
    uuid: uuidv4(),
  },
  {
    report_name: SCORPIO_STOCK_REPORT_LABELS[SCORPIO_AGGREGATION.WEEK],
    aggregation_type_id: SCORPIO_AGGREGATION.WEEK,
    start_date: undefined,
    finish_date: undefined,
    is_current: false,
    uuid: uuidv4(),
  },
  {
    report_name: SCORPIO_STOCK_REPORT_LABELS[SCORPIO_AGGREGATION.MONTH],
    aggregation_type_id: SCORPIO_AGGREGATION.MONTH,
    start_date: undefined,
    finish_date: undefined,
    is_current: false,
    uuid: uuidv4(),
  },
];

export const scorpio_aggregation_types: AggregationTypeOption[] = [
  {
    label: SCORPIO_AGGREGATION_TYPE_LABELS[SCORPIO_AGGREGATION.DAY],
    aggregation_type: SCORPIO_AGGREGATION.DAY,
  },
  {
    label: SCORPIO_AGGREGATION_TYPE_LABELS[SCORPIO_AGGREGATION.WEEK],
    aggregation_type: SCORPIO_AGGREGATION.WEEK,
  },
  {
    label: SCORPIO_AGGREGATION_TYPE_LABELS[SCORPIO_AGGREGATION.MONTH],
    aggregation_type: SCORPIO_AGGREGATION.MONTH,
  },
];
